import { useRouteError } from "@remix-run/react";

export function ErrorBoundaryy() {
  const error = useRouteError();

  // If the error is a 404 error, we can render a custom 404 page here
  if (error.status === 404) {
    return (
      <div className="px-6">
        <div className="bg-primary/20 max-w-7xl mx-auto rounded my-24">
          <div className="flex flex-col items-center py-24">
            <div className="text-secondary font-bold text-7xl">404</div>
            <div className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">This page does not exist</div>
            <div className="font-medium text-sm md:text-xl lg:text-2xl mt-8 opacity-60">
              Try searching for what you're looking for!
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Otherwise, we can render a generic error message
  return <div>Something went wrong...</div>;
}
